import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Introduction to Data Visualization for the Web`}</h1>
    <h6>{`MAY 2023`}</h6>
    <h2>{`Overview`}</h2>
    <p>{`This is a selection of core course content for INFO474: Introduction to Data Visualization for the Web, a project based course I taught during 2021 and 2022 at the University of Washington. The structure and pacing of the course owes a debt to Jeff Heer's course for the CS department, from which the course was adapted with permission and which is available online here: `}<a parentName="p" {...{
        "href": "https://courses.cs.washington.edu/courses/cse442/22wi/"
      }}>{`https://courses.cs.washington.edu/courses/cse442/22wi/`}</a></p>
    <h3>{`Textbooks & Core Resources`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www2.jufejus.org.ar/www.jufejus.org.ar/images/doc/ACTIVIDADES/Estadisticas/Jornadas%20de%20Capacitacion/Material/JornadasX/Tufte/The%20Visual%20Display%20of%20Q%20Info.pdfLinks"
      }}>{`The Visual Display of Quantitative Information`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.are.na/block/2733469"
      }}>{`Envisioning Information`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/History-Information-Graphics-multilingual-Rendgen/dp/3836567679/"
      }}>{`History of Information Graphics`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/Visualization-Analysis-Design-AK-Peters/dp/1466508914"
      }}>{`Visualization Analysis & Design`}</a></p>
    <p>{`This website's collection of interactive tools: `}<a parentName="p" {...{
        "href": "https://colinmegill.com/explorers"
      }}>{`https://colinmegill.com/explorers`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/playlist?list=PLT4XLHmqHJBeB5LwmRmo6ln-m7K3lGvrk"
      }}>{`Tamara Munzner's Lectures from IEEE 2021`}</a>{` is extensive and covers most of her landmark book `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Visualization-Analysis-Design-AK-Peters/dp/1466508914"
      }}>{`Visualization Analysis & Design`}</a>{`. `}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLT4XLHmqHJBeB5LwmRmo6ln-m7K3lGvrk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/xplSAMwlTmY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <h3>{`The history of data visualization`}</h3>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/N00g9Q9stBo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <h3>{`Minimalism & Graphical Excellence`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.edwardtufte.com/bboard/q-and-a-fetch-msg?msg_id=0003vq"
      }}>{`Maps moving in time: a standard of excellence for data displays`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://journals.sagepub.com/doi/10.1177/15291006211051956"
      }}>{`The Science of Visual Data Communication: What Works`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.darkhorseanalytics.com/remove-to-improve"
      }}>{`Data looks better naked series from Darkhorse Analytics`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://medium.com/@hint_fm/design-and-redesign-4ab77206cf9"
      }}>{`Design & Redesign`}</a></p>
    <h3>{`Narratives`}</h3>
    <p><a parentName="p" {...{
        "href": "https://distill.pub/2020/communicating-with-interactive-articles/"
      }}>{`Communicating with interactive articles — Distill.pub`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://michaelnielsen.org/reinventing_explanation/"
      }}>{`Reinventing explanation by Michael Nielsen`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://ciechanow.ski/gps/"
      }}>{`GPS by Bartosz Ciechanowski`}</a></p>
    <h3>{`Frontend Development`}</h3>
    <p>{`Overview: `}<a parentName="p" {...{
        "href": "https://frontendmasters.com/guides/front-end-handbook/2019/"
      }}>{`Frontend Handbook`}</a></p>
    <p>{`Learn JS: `}<a parentName="p" {...{
        "href": "https://eloquentjavascript.net/"
      }}>{`Eloquent Javascript`}</a></p>
    <p>{`Learn TypeScript: `}<a parentName="p" {...{
        "href": "https://www.codecademy.com/learn/learn-typescript"
      }}>{`Codecademy TypeScript`}</a></p>
    <p>{`Learn React: `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=w7ejDZ8SWv8&ab_channel=TraversyMedia"
      }}>{`ReactJS crash course`}</a></p>
    <p>{`Learn Recoil for client side state management: `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=BchtCWxs7sA&t=381s&ab_channel=CodeWithKristian"
      }}>{`Learn RecoilJS in 10 minutes`}</a></p>
    <p>{`Working with layouts, fonts and CSS: `}<a parentName="p" {...{
        "href": "https://jgthms.com/web-design-in-4-minutes/"
      }}>{`Web Design in 4 Minutes`}</a></p>
    <p>{`Visualization utilities (scales, CSV parsers): `}<a parentName="p" {...{
        "href": "https://2019.wattenberger.com/blog/d3"
      }}>{`Learn D3 — Wattenberger`}</a></p>
    <p>{`Handling data in JS: `}<a parentName="p" {...{
        "href": "http://learnjsdata.com/"
      }}>{`Learn JS Data`}</a>{` & `}<a parentName="p" {...{
        "href": "https://observablehq.com/@dakoop/learn-js-data"
      }}>{`Observable`}</a></p>
    <p>{`React based visualization library: `}<a parentName="p" {...{
        "href": "https://airbnb.io/visx/"
      }}>{`AirBnB VisX`}</a>{` && `}<a parentName="p" {...{
        "href": "https://medium.com/airbnb-engineering/introducing-visx-from-airbnb-fd6155ac4658"
      }}>{`blog post`}</a></p>
    <p>{`UI Framework for data dense applications: `}<a parentName="p" {...{
        "href": "https://blueprintjs.com/docs/"
      }}>{`BlueprintJS`}</a></p>
    <p>{`Build system and application bootstrap: `}<a parentName="p" {...{
        "href": "https://vitejs.dev/"
      }}>{`Vite.js`}</a></p>
    <p>{`Host: `}<a parentName="p" {...{
        "href": "https://www.netlify.com/blog/2016/07/22/deploy-react-apps-in-less-than-30-seconds/"
      }}>{`How to deploy React Apps in less than 30 Seconds`}</a></p>
    <h3>{`Exploratory Data Analysis`}</h3>
    <p><a parentName="p" {...{
        "href": "https://towardsdatascience.com/a-gentle-introduction-to-exploratory-data-analysis-f11d843b8184"
      }}>{`A Gentle Introduction to Exploratory Data Analysis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.darkhorseanalytics.com/blog/visualizing-distributions-3"
      }}>{`Visualizing Distributions`}</a></p>
    <h3>{`Perception, attention and cognition`}</h3>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/6Ya8XBgqMts" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <h3>{`Interactivity`}</h3>
    <p>{`The best primers on the role of interactivity in visualization and complex applications, both in terms of philosophy and practice, are from Brett Victor's seminal writing and talks. `}</p>
    <p><a parentName="p" {...{
        "href": "http://worrydream.com/MagicInk/"
      }}>{`Magic Ink`}</a>{` — `}<a parentName="p" {...{
        "href": "http://worrydream.com/MagicInk/MagicInk.pdf"
      }}>{`PDF`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://worrydream.com/LadderOfAbstraction/"
      }}>{`Up and Down the Ladder of Abstraction: A Systematic Approach to Interactive Visualization`}</a>{` — `}<a parentName="p" {...{
        "href": "https://news.ycombinator.com/item?id=3099595"
      }}>{`"This is simply one of the best guides I've seen to anything...ever."`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://worrydream.com/ExplorableExplanations/"
      }}>{`Explorable Explanations`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://worrydream.com/Tangle/"
      }}>{`Tangle`}</a></p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/oUaOucZRlmE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/PUv66718DII" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/ef2jpjTEB5U" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <p>{`Also see Nicky Case's explorers, aggregated with many others at: `}<a parentName="p" {...{
        "href": "https://explorabl.es/"
      }}>{`Explorable Explanations`}</a></p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/PCwtsK_FhUw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <h3>{`Machine learning`}</h3>
    <p><a parentName="p" {...{
        "href": "http://visxai.io/2022.html#hall-of-fame"
      }}>{`5th Workshop on Visualization for AI Explainability`}</a>{` `}</p>
    <p>{`This website's collection of interactive tools: `}<a parentName="p" {...{
        "href": "https://colinmegill.com/explorers"
      }}>{`https://colinmegill.com/explorers`}</a></p>
    <h3>{`Color`}</h3>
    <p><a parentName="p" {...{
        "href": "https://blog.datawrapper.de/colorguide/"
      }}>{`Your friendly guide to color in data visualization`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://visualisingdata.com/2015/01/make-grey-best-friend/"
      }}>{`Make grey your best friend`}</a></p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/FTKP0Y9MVus" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      